import "./Styles/Header.css";

import { Navigate, useNavigate } from "react-router-dom";
const Header = () => {
  const navigate = useNavigate();
  return (
    <div className="header">
      <div className="header_left"></div>
      <div className="header_center">
        <h1 className="headerlink" onClick={() => navigate("/")}>
          Home
        </h1>
        <h1 className="headerlink" onClick={() => navigate("/properties")}>
          Properties
        </h1>

        <h1 className="headerlink" onClick={() => navigate("/about")}>
          About
        </h1>
        <h1 className="headerlink" onClick={() => navigate("/contact")}>
          Contact
        </h1>
      </div>
      <div className="header_right"></div>
    </div>
  );
};

export default Header;
