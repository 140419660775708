import "./Styles/Properties.css";
import React, { useState } from "react";
import { useFormspark } from "@formspark/use-formspark";

const Properties = () => {
  return (
    <div className="propertiesPages" id="properties">
      <div className="propertyPage1">
        {" "}
        <div className="bodyPage1">
          <h1 className="titlePage1">P R O P E R T I E S</h1>
        </div>{" "}
      </div>
      <div className="galleryPage">
        <div className="galleryContainerLeft">
          <img className="homeImg1" src="21026.png" />
          <img className="homeImg2" src="336.png" />
          <img className="homeImg3" src="531.png" />
        </div>
        <div className="galleryContainerRight">
          <img className="homeImg4" src="845.png" />
          <img className="homeImg5" src="2000.png" />
          <img className="homeImg6" src="6227.png" />
        </div>
      </div>
    </div>
  );
};

export default Properties;
