import "./Styles/Home.css";

const Home = () => {
  return (
    <div className="home" id="home">
      <img className="jmlogo" src="jm2.png" />
    </div>
  );
};

export default Home;
