import "./Styles/About.css";

const About = () => {
  return (
    <div className="aboutPage" id="about">
      <div className="aboutHeader">
        <h1 className="aboutTitle">About Jet</h1>
        <h1 className="caption">Experienced. Knowledgeable. Professional.</h1>
      </div>
      <div className="aboutMain">
        <div className="photoContainer">
          <img className="photo" src="headshot2.png" />
        </div>
        <div className="textContainer">
          <div></div>

          <a className="pgph1">
            Raised in Chicago, Jet grew up with exposure to the many facets of
            the city and suburbs. Art galleries, real estate, architecture,
            theater, and athletics. His move to LA was prompted by his
            admittance into USC, where he graduated with Honors and wrote his
            first book. His work in real estate allows him to blend his
            passions, and employ his affinity to help serve & advise others.
          </a>
          <a className="pgph2">
            Jet’s multi-faceted background has enabled him to better understand
            and help execute the needs of a wide variety of clients. His ability
            to creatively approach and identify opportunities, his preternatural
            skill for marketing and communication, and his work ethic has
            allowed him to create tremendous success for himself and his
            clients. Working with Jet will allow for an extremely tailored,
            genuine, and refined experience.
          </a>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default About;
