import "./Styles/Contact.css";
import React from "react";
import ReactDOM from "react-dom";
import { SocialIcon } from "react-social-icons";

const Contact = () => {
  return (
    <div className="contactPage" id="contact">
      <div className="contactBody">
        <div className="contactMeTextContainer">
          <h1 className="contactMeHeading">CONTACT ME</h1>
          <p className="contactMeText1">Los Angeles, CA</p>
          <p className="contactMeText2">jetm@kw.com</p>
          <p className="contactMeText3">847.602.1023</p>

          <SocialIcon
            style={{ height: 40, width: 40 }}
            className="instagram"
            url="https://www.instagram.com/jetmendes.re/"
          />
          <SocialIcon
            style={{ height: 40, width: 40 }}
            className="linkedin"
            url="https://www.linkedin.com/in/jetmendes/"
          />
          <SocialIcon
            style={{ height: 40, width: 40 }}
            className="youtube"
            url="https://www.youtube.com/channel/UCGhRk0KJC86HVnJgWWu89yw/featured"
          />
        </div>
        <div className="submitFormContainer">
          {/* Insert the email form submission in this div */}
          <div>
            <form action="https://submit-form.com/hUi0qtK3">
              <div className="submitNameContainer">
                <label className="nameLabel" for="name">
                  Name
                </label>
                <input
                  className="nameBox"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  required=""
                />
              </div>
              <div className="submitEmailContainer">
                <label className="emailLabel" for="email">
                  Email
                </label>
                <input
                  className="emailBox"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required=""
                />
              </div>
              <div className="submitMessageContainer">
                <label className="messagesLabel" for="message">
                  Message
                </label>
                <textarea
                  className="messagesBox"
                  id="message"
                  name="message"
                  placeholder="Message"
                  required=""
                ></textarea>
              </div>
              <div className="submitButtonContainer">
                <button className="submitBtn" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="contactFooterContainer">
        <div className="contactFooterLeft"></div>
        <div className="contactFooterCenter">
          <img className="attlasLogo" src="./beachrocklogo1.png" />
          <a className="footerBre">BRE #01428775</a>
          <a className="footerDre">DRE #02128708</a>
        </div>
        <div className="contactFooterRight"></div>
      </div>
    </div>
  );
};

export default Contact;
